<template>
  <v-container>
    <v-row
      dense
      justify="center"
    >
      <v-col
        cols="12"
        md="8"
        xl="8"
      >
        <v-row dense>
          <v-col cols="12">
            <v-card>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              grow
              show-arrows
            >
              <v-tab
                v-for="item in items1"
                :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            xl="3"
            v-for="i in 10"
            :key="i"
          >
            <info />
          </v-col>
        </v-row>
      </v-col>
      <v-col
      class="hidden-sm-and-down"
        cols="12"
        md="4"
        xl="3"
      >
        <v-card>
          <v-card-title class="text-center">Información de servicio</v-card-title>
          <v-card-text>
            <busqueda />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import busqueda from "@/components/busqueda/Busqueda.vue";
import info from "@/components/cards/Info.vue";
export default {
  components: {
    busqueda,
    info,
  },
  data() {
    return {
      items: [
        {
          src:
            "https://scontent.fcjs3-1.fna.fbcdn.net/v/t1.0-9/151946200_103123711826206_7000063551352803801_o.png?_nc_cat=105&ccb=3&_nc_sid=e3f864&_nc_eui2=AeGWIVaTMFkgUuRqvEx1IbwIAt6hj6MpuS8C3qGPoym5L6yHj5UPzIQDPi1fPf5we0xv03dL4zxZ4PWmCPHWAvAu&_nc_ohc=mQBGgxt4bYsAX_snIXe&_nc_ht=scontent.fcjs3-1.fna&oh=768b5f11bbcab2eb6589a0cc8e3e1387&oe=6053E643",
        },
        {
          src: "https://images2.alphacoders.com/262/262710.jpg",
        },
        {
          src: "https://i.blogs.es/18456e/4k/1366_2000.jpg",
        },
        {
          src: "https://images.alphacoders.com/723/7231.jpg",
        },
      ],
      tab: null,
      items1: ["Anuncios", "Noticias", "Top Talleres", "Top Tecnicos", "cerca de ti"],
      tarjetas: [
        {
          titulo: "Misión",
          imagen: "http://intermedia.eus/wp-content/uploads/2015/09/mision.png",
          texto:
            "Facilitar la administración de talleres mediante una herramienta de software intuitiva",
        },
        {
          titulo: "Visión",
          imagen:
            "https://es.familydoctor.org/wp-content/uploads/2009/09/vision-705x470.jpg",
          texto: "Texto sin definición",
        },
        {
          titulo: "Acerca de",
          imagen:
            "https://download.logo.wine/logo/Smart_Communications/Smart_Communications-Logo.wine.png",
          texto:
            "Este sistema se encuentra en desarrollo por Jesús Medina Calderón",
        },
      ],
    };
  },
  methods: {
    buscar(id) {
      alert(`No se encontro información respecto al servicio ${id}`);
    },
  },
};
</script>