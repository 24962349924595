<template>
  <v-card
    :loading="loading"
    class="mb-10"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      src="https://picsum.photos/500"
    ></v-img>

    <v-card-title>Titulo noticia Tecnologica</v-card-title>

    <v-card-text>
      <v-list-item three-line>
        <v-list-item-content>
        <v-list-item-subtitle aria-expanded>{{texto}}</v-list-item-subtitle>
      </v-list-item-content>
        </v-list-item>
        <v-btn block class="success">Ver</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
    texto:
      "Hace poco te hablamos de la terrible vulnerabilidad encontrada en ShareIt, la app más popular en Android para compartir archivos entre smartphones. Desde hace tiempo Hace poco te hablamos de la terrible vulnerabilidad encontrada en ShareIt, la app más popular en Android para compartir archivos entre smartphones. Desde hace tiempo Hace poco te hablamos de la terrible vulnerabilidad encontrada en ShareIt, la app más popular en Android para compartir archivos entre smartphones. Desde hace tiempo",
  }),

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>
<style scoped>
.scroll {
   overflow-y: scroll
}
</style>