<template>
  <v-card>
    <v-card-title>
      Estado del servicio
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="cerrar()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-timeline dense>
        <v-timeline-item
          v-for="(dato, id) in datos"
          :key="id"
          color="success"
        >
          <template v-slot:opposite>
            <span></span>
          </template>
          <v-hover
            v-slot:default="{ hover }"
            close-delay="200"
          >
            <v-card
              :elevation="hover ? 12 : 0"
              @click="detalles()"
            >
              <v-card-title>
                {{dato.titulo}}
              </v-card-title>
              <v-card-subtitle>
                {{dato.fecha}}
              </v-card-subtitle>
              <v-card-text>
                {{dato.contenido}}
              </v-card-text>
            </v-card>
          </v-hover>
        </v-timeline-item>
        <v-timeline-item>
          <v-text-field
            v-model="contenido"
            hide-details
            flat
            label="Agregar registro..."
            solo
            @keydown.enter="agregar(contenido)"
          ></v-text-field>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      contenido: "",
      datos: [
        {
          emisor: "SERVICIO",
          tipo: "INICIO",
          fecha: "16/08/1995 12:33",
          titulo: "Solicutud abierta",
          contenido: "Se solicito el ingreso al servicio"
        },
        {
          emisor: "SERVICIO",
          tipo: "EJECUCION",
          fecha: "16/08/1995 12:43",
          titulo: "En reparación",
          contenido: "Se toma el servicio para realizar la ejecución"
        },
        {
          emisor: "SERVICIO",
          tipo: "PAUSA",
          fecha: "16/08/1995 12:53",
          titulo: "En pausa",
          contenido: "El centro de carga se sobrecalento y es necesario conseguir una nueva refaccion"
        },
        {
          emisor: "SERVICIO",
          tipo: "PAUSA",
          fecha: "16/08/1995 12:53",
          titulo: "Servicio en pausa",
          contenido: "El centro de carga se sobrecalento y es necesario conseguir una nueva refaccion"
        },
        {
          emisor: "SERVICIO",
          tipo: "PAUSA",
          fecha: "16/08/1995 12:53",
          titulo: "Servicio en pausa",
          contenido: "El centro de carga se sobrecalento y es necesario conseguir una nueva refaccion"
        },
        {
          emisor: "SERVICIO",
          tipo: "PAUSA",
          fecha: "16/08/1995 12:53",
          titulo: "Servicio en pausa",
          contenido: "El centro de carga se sobrecalento y es necesario conseguir una nueva refaccion"
        }
      ]
    }
  },
  methods: {
    cerrar() {
      this.$emit('cerrar_estado', false);
    },
    agregar() {
      this.contenido = "";
    },
    detalles(){

    }
  }

}
</script>