<template>
  <v-row
    dense
    class="fill-height"
    justify="center"
    align="center"
  >
    <v-col cols="12">
      <v-text-field
        v-model="id_servicio"
        outlined
        light
        placeholder="Codigo de servicio"
        append-icon="mdi-magnify"
        @click:append="buscar(id_servicio)"
        hide-details
        @keyup.enter="buscar(id_servicio)"
      ></v-text-field>
    </v-col>
    <v-dialog
      class="hidden-sm-and-down"
      scrollable
      transition="dialog-bottom-transition"
      v-model="dialog"
      persistent
      fullscreen
      
    >
      <estado-servicio @cerrar_estado="cerrar_estado"/>
    </v-dialog>
  </v-row>
</template>

<script>
import estadoServicio from './resultado/Estado_Servicio.vue';

export default {
  components: {
    estadoServicio
  },
  data() {
    return {
      id_servicio: "",
      dialog: false,
    }
  },
  methods: {
    buscar() {
      this.dialog = true;
    },
    cerrar_estado(dato){
      console.log(dato);
      this.dialog = dato;
    }
  }
}
</script>